import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Routes, useParams } from 'react-router-dom'
import { is, isEmpty, isNil } from 'ramda'
import { isNilOrEmpty } from 'ramdasauce'
import cc from 'classcat'
import withRouter from '../../components/wrapperReactRouterDom'

import { ICON_PLUS, ICON_INFO } from '../../config/constants'

import { isPureEngageStudy, getAddPatientButtonToolkit, isStudyArchived } from '../../utils/StudyHelper'

import PatientActions from '../../redux/PatientRedux'
import VisitActions from '../../redux/VisitRedux'
import SetActions from '../../redux/SetRedux'
import PdiActions from '../../redux/PdiRedux'

import Icon from '../../components/Icon'

import CollapsePickerItem from '../../components/collapsePicker/CollapsePickerItem'
import CollapsePicker from '../../components/collapsePicker/CollapsePicker'

import SidebarButton from '../../components/buttons/SidebarButton'

import StudyLink from '../../components/links/StudyLink'
import SiteLink from '../../components/links/SiteLink'
import PatientLink from '../../components/links/PatientLink'

import BaseModal from '../../components/modals/BaseModal'
import CreatePatientForm from '../../components/modals/CreatePatientForm'
import ActivatedDeviceModal from '../../components/modals/ActivatedDeviceModal'
import openFile from '../../utils/FileHelper'

import EmptyContainer from './EmptyContainer'


function SelectedStudy({ studies }) {
  const studyId = useParams().study_id
  const matchingStudy = !isNil(studyId) && is(Array, studies) && !isEmpty(studies) && studies.find(study => study.id === studyId)

  return matchingStudy && !isNilOrEmpty(matchingStudy) ? (
    <CollapsePickerItem
      itemClass="selected collapsepicker-item--preview"
      onItemClick={ () => { } }
      tabIndex={ -1 }>
      <span>
        <strong>
          { matchingStudy.displayName }
        </strong>
      </span>
    </CollapsePickerItem>
  ) : null
}

function SelectedSite({ sites }) {
  const siteId = useParams().site_id
  const matchingSite = !isNil(siteId) && is(Array, sites) && !isEmpty(sites) && sites.find(site => site.id === siteId)

  return matchingSite && !isNilOrEmpty(matchingSite) ? (
    <CollapsePickerItem
      itemClass="selected collapsepicker-item--preview"
      onItemClick={ () => { } }
      tabIndex={ -1 }>
      <span>
        { `Site ${siteId}` }
      </span>
    </CollapsePickerItem>
  ) : null
}

function SelectedPatient({ patients }) {
  const patientId = useParams().patient_id
  const matchingPatient = !isNil(patientId) && is(Array, patients) && !isEmpty(patients) && patients.find(patient => patient.id === patientId)
 
  return matchingPatient && !isNilOrEmpty(matchingPatient) ? (
    <CollapsePickerItem
      itemClass="selected collapsepicker-item--preview"
      onItemClick={ () => { } }
      tabIndex={ -1 }>
      <span>
        { `Patient ${patientId}` }
      </span>
    </CollapsePickerItem>
  ) : null
}

export class SidebarContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shownPicker: 0,
      showModalAddPatient: false,
      showActivatedDeviceModal: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // ADD PATIENT
    const {
      newPatient,
      resetAddPatientError,
      study,
    } = this.props

    const newPatientHasBeenAdded = newPatient && !nextProps.newPatient && !nextProps.addPatientError
    if (newPatientHasBeenAdded) {
      this._closeNewPatientModal(resetAddPatientError)()
      if (!isPureEngageStudy(study)) {
        this._openActivatedPatientModal()
      }
    }
  }

  render() {
    const { children, activatedPatient, studies, study, site, availablePatientIds, patients, sidebarCollapsed } = this.props
    const { addPatient, addPatientError, resetAddPatientError, busyAddingPatient } = this.props
    const { setSite, setStudy } = this.props
    const { fetchPatient, fetchVisits, fetchAllPdis } = this.props
    const { fetchAvailablePatientIds, busyFetchingAvailablePatientIds, fetchAvailablePatientIdsError } = this.props
    const { shownPicker, showModalAddPatient, showActivatedDeviceModal } = this.state

    const hasStudies = !isNilOrEmpty(studies)
    const possibleLanguages = site && site.availableLanguages
    const hasSites = !isNilOrEmpty(study) && !isNilOrEmpty(study.sites)
    const sites = hasSites && study.sites
    const addPatientButtonTooltip = !isNilOrEmpty(study) && getAddPatientButtonToolkit(study.resources)
    const studyIsArchived = !isNilOrEmpty(study) && isStudyArchived(study.workflow)

    const hasPatients = !isNilOrEmpty(patients)

    const sidebarClassNames = cc([
      'sidebar-container',
      { "sidebar-container--active": sidebarCollapsed },
    ])

    return (
      <div className={ sidebarClassNames }>
        { /* STUDIES */ }
        { hasStudies && this._renderStudyPicker(shownPicker, studies, setStudy) }
        { /* SITES */ }
        { study && hasSites && this._renderSitesPicker(shownPicker, study.id, sites, setSite) }
        { /* PATIENTS */ }
        { study && site && hasPatients && this._renderPatientsPicker(shownPicker, study.id, site.id, patients, fetchPatient, fetchVisits, fetchAllPdis) }
        { study && site && this._renderAddPatientButton(study.id, site.country, site.id, fetchAvailablePatientIds, addPatientButtonTooltip, studyIsArchived) }

        { /* EMPTY CONTAINER TO PUSH BOTTOM BUTTON(S) DOWN */ }
        <EmptyContainer />
        { /* PRIVACY NOTICE */ }
        { this._renderDisclaimerNoticeButton() }

        { /* MODALS */ }
        { study && site && showModalAddPatient && this._renderNewPatientModal(addPatient, resetAddPatientError, addPatientError, site, possibleLanguages, study.id, availablePatientIds, busyAddingPatient, study.studyType, busyFetchingAvailablePatientIds, fetchAvailablePatientIdsError) }
        { showActivatedDeviceModal && this._renderActivatedPatientModal(activatedPatient) }
        { /* PARENT CHILDREN */ }
        { children }
      </div>
    )
  }

  // STUDY PICKER
  _renderStudyPicker(shownPicker, studies, setStudy) {
    return (
      <CollapsePicker
        isOpen={ shownPicker === 1 }
        title="STUDIES"
        selectorTitle="Study Overview"
        onSelectTitle="Select a study"
        containerClass="blue-picker"
        onSelectorClick={ this._openCertainPicker(shownPicker, 1) }>
        { shownPicker === 1 && this._renderStudyLinks(studies, setStudy) }
        { shownPicker !== 1 && this._renderSelectedStudy(studies) }
      </CollapsePicker>
    )
  }

  _renderStudyLinks(studies, setStudy) {
    return is(Array, studies) && !isEmpty(studies) && studies.map(study => (
      <StudyLink
        study={ study }
        handleClick={ this._handleStudySelection(studies, study.id, setStudy) }
        key={ study.id } />
    ))
  }

  _renderSelectedStudy = studies => (
    <Routes>
      <Route
        path="/study/:study_id/*"
        element={ <SelectedStudy studies={ studies } /> } />
    </Routes>
  )

  _handleStudySelection = (studies, studyId, setStudy) => () => {
    const matchingStudy = !isNil(studyId) && is(Array, studies) && !isEmpty(studies) && studies.find(study => study.id === studyId)
   
    if (matchingStudy) {
      setStudy(matchingStudy)
    }
   
    this._closePicker()
  }

  // SITE PICKER
  _renderSitesPicker(shownPicker, studyId, sites, setSite) {
    return (
      <CollapsePicker
        isOpen={ shownPicker === 2 }
        title="SITES"
        selectorTitle="Site Overview"
        onSelectTitle="Select a site"
        containerClass="blue-picker"
        onSelectorClick={ this._openCertainPicker(shownPicker, 2) }>
        { shownPicker === 2 && this._renderSiteLinks(studyId, sites, setSite) }
        { shownPicker !== 2 && this._renderSelectedSite(sites) }
      </CollapsePicker>
    )
  }

  _renderSiteLinks(studyId, sites, setSite) {
    return is(Array, sites) && !isEmpty(sites) && sites.map(site => (
      <SiteLink
        parentPath={ `/study/${studyId}` }
        siteId={ site.id }
        handleClick={ this._handleSiteSelection(studyId, site.id, sites, setSite) }
        key={ site.id } />
    ))
  }

  _renderSelectedSite = sites => (
    <Routes>
      <Route
        path="/study/:study_id/site/:site_id/*"
        element={ <SelectedSite sites={ sites } /> } />
    </Routes>
  )

  _handleSiteSelection = (studyId, siteId, sites, setSite) => () => {
    const matchingSite = !isNil(siteId) && is(Array, sites) && !isEmpty(sites) && sites.find(site => site.id === siteId)

    if (matchingSite) {
      setSite(studyId, matchingSite)
    }

    this._closePicker()
  }

  // PATIENT PICKER
  _renderPatientsPicker(shownPicker, studyId, siteId, patients, fetchPatient, fetchVisits, fetchAllPdis) {
    return (
      <CollapsePicker
        isOpen={ shownPicker === 3 }
        title="PATIENTS"
        selectorTitle="Patients Overview"
        onSelectTitle="Select a patient"
        containerClass="blue-picker"
        onSelectorClick={ this._openCertainPicker(shownPicker, 3) }>
        { shownPicker === 3 && this._renderPatientLinks(patients, studyId, siteId, fetchPatient, fetchVisits, fetchAllPdis) }
        { shownPicker !== 3 && this._renderSelectedPatient(patients) }
      </CollapsePicker>
    )
  }

  _renderPatientLinks(patients, studyId, siteId, fetchPatient, fetchVisits, fetchAllPdis) {
    return is(Array, patients) && !isEmpty(patients) && patients.map(patient => (
      <PatientLink
        parentPath={ `/study/${studyId}/site/${siteId}` }
        patientId={ patient.id }
        handleClick={ this._handlePatientSelection(studyId, siteId, patients, fetchPatient, fetchVisits, fetchAllPdis, patient.id) }
        key={ patient.id } />
    ))
  }

  _renderSelectedPatient = patients => (
    <Routes>
      <Route
        path="/study/:study_id/site/:site_id/patient/:patient_id/*"
        element={ <SelectedPatient patients={ patients } /> } />
    </Routes>
  )

  _handlePatientSelection = (studyId, siteId, patients, fetchPatient, fetchVisits, fetchAllPdis, patientId) => () => {
    const matchingPatient = !isNil(patientId) && is(Array, patients) && !isEmpty(patients) && patients.find(patient => patient.id === patientId)

    if (matchingPatient) {
      fetchPatient(studyId, patientId, true)
      fetchVisits(studyId, patientId, true)
      fetchAllPdis(studyId, patientId)
    }

    this._closePicker()
  }

  // SIDEBAR PICKERS
  _openCertainPicker = (currentPickerShown, pickerToOpen) => () => {
    this.setState({ shownPicker: currentPickerShown === pickerToOpen ? 0 : pickerToOpen })
  }

  _closePicker = () => {
    this.setState(() => ({ shownPicker: 0 }))
  }

  // CREATE PATIENT
  _renderAddPatientButton(studyId, countryId, siteId, fetchAvailablePatientIds, addPatientButtonTooltip, studyIsArchived) {
    return (
      <SidebarButton
        buttonClass="blue-button"
        handleClick={ this._openNewPatientModal(studyId, countryId, siteId, fetchAvailablePatientIds) }
        disabled={ studyIsArchived }
        tooltip={ addPatientButtonTooltip }
        id="button-add-patient">
        <Icon
          name={ ICON_PLUS }
          color="white" />
        <span>
          Add Patient
        </span>
      </SidebarButton>
    )
  }

  _renderNewPatientModal(addPatient, resetAddPatientError, addPatientError, site, possibleLanguages, studyId, availablePatientIds, busyAddingPatient, studyType, busyFetchingAvailablePatientIds, fetchAvailablePatientIdsError) {
    const loaders = {
      busyAddingPatient,
      busyFetchingAvailablePatientIds,
    }
    const errors = {
      addPatientError,
      fetchAvailablePatientIdsError,
    }
    return (
      <BaseModal
        title="Add Patient"
        handleClose={ this._closeNewPatientModal(resetAddPatientError) }
        forceInteraction={ busyAddingPatient }>
        <CreatePatientForm
          possibleLanguages={ possibleLanguages }
          availablePatientIds={ availablePatientIds }
          handleCanceled={ this._closeNewPatientModal(resetAddPatientError) }
          handleConfirmed={ this._newPatientConfirmed(addPatient, studyId, site.id, site.country, studyType) }
          loading={ loaders }
          errors={ errors }
          isAPureEngageStudy={ isPureEngageStudy({ studyType }) }
        />
      </BaseModal>
    )
  }

  _openNewPatientModal = (studyId, countryId, siteId, fetchAvailablePatientIds) => () => {
    this.setState(() => ({ showModalAddPatient: true }))
    fetchAvailablePatientIds(studyId, countryId, siteId)
  }

  _closeNewPatientModal = resetAddPatientError => () => {
    this.setState(() => ({ showModalAddPatient: false }))
    resetAddPatientError()
  }

  _newPatientConfirmed = (addPatient, studyId, siteId, countryId, studyType) => (newPatient) => {
    const { patientId, ...restNewPatientParameters } = newPatient
    addPatient(studyId, countryId, siteId, patientId, studyType, restNewPatientParameters)
  }

  // ACTIVATED PATIENT MODAL
  _renderActivatedPatientModal(activatedPatient) {
    // TODO: refactor to saga success call (and/or extract to a helper?)
    const result = activatedPatient && activatedPatient._embedded && activatedPatient._embedded.result // eslint-disable-line no-underscore-dangle
    const patientId = activatedPatient && activatedPatient.id
    const { pin, applicationId } = result
    return (
      <BaseModal
        title={ `Device Activated (PATIENT ${patientId})` }
        handleClose={ this._closeActivatedPatientModal }
        forceInteraction={ true }>
        <ActivatedDeviceModal
          pin={ pin }
          applicationId={ applicationId }
          handleConfirmed={ this._closeActivatedPatientModal } />
      </BaseModal>
    )
  }

  _openActivatedPatientModal = () => {
    this.setState(() => ({ showActivatedDeviceModal: true }))
  }

  _closeActivatedPatientModal = () => {
    this.setState(() => ({ showActivatedDeviceModal: false }))
  }

  // PRIVACY NOTICE
  _renderDisclaimerNoticeButton() {
    return (
      <SidebarButton
        buttonClass="blue-button"
        handleClick={ this._openDisclaimerModal }
        id="button-open-disclaimer">
        <Icon
          name={ ICON_INFO }
          color="white" />
        <span>
          Privacy Notice
        </span>
      </SidebarButton>
    )
  }

  _openDisclaimerModal = () => {
    openFile('/files/Data-Privacy-Notice-myUCB.pdf')
  }
}

SelectedStudy.propTypes = { studies: PropTypes.array.isRequired }

SelectedSite.propTypes = { sites: PropTypes.array.isRequired }

SelectedPatient.propTypes = { patients: PropTypes.array.isRequired }

SidebarContainer.propTypes = {
  children: PropTypes.node,
  sidebarCollapsed: PropTypes.bool.isRequired,
  // set
  studies: PropTypes.array,
  study: PropTypes.object,
  site: PropTypes.object,
  setStudy: PropTypes.func.isRequired,
  setSite: PropTypes.func.isRequired,
  // patients
  patients: PropTypes.array,
  newPatient: PropTypes.object,
  activatedPatient: PropTypes.object,
  addPatientError: PropTypes.object,
  addPatient: PropTypes.func.isRequired,
  resetAddPatientError: PropTypes.func.isRequired,
  fetchPatient: PropTypes.func.isRequired,
  availablePatientIds: PropTypes.array,
  busyAddingPatient: PropTypes.bool.isRequired,
  fetchAvailablePatientIds: PropTypes.func.isRequired,
  busyFetchingAvailablePatientIds: PropTypes.bool.isRequired,
  fetchAvailablePatientIdsError: PropTypes.object,
  // visits
  fetchVisits: PropTypes.func.isRequired,
  // pdis
  fetchAllPdis: PropTypes.func.isRequired,
}

SidebarContainer.defaultProps = {
  children: null,
  // set
  studies: [],
  study: null,
  site: null,
  // patients
  patients: [],
  newPatient: null,
  activatedPatient: null,
  addPatientError: null,
  availablePatientIds: [],
  fetchAvailablePatientIdsError: null,
}

export const mapStateToProps = state => ({
  // set
  studies: state.set.studies,
  study: state.set.study,
  site: state.set.site,
  // patients
  patient: state.patients.patient, 
  patients: state.patients.patientList,
  activatedPatient: state.patients.activatedPatient,
  newPatient: state.patients.newPatient,
  addPatientError: state.patients.addPatientError,
  availablePatientIds: state.patients.availablePatientIds,
  fetchAvailablePatientIdsError: state.patients.fetchAvailablePatientIdsError,
  busyAddingPatient: state.patients.busyAddingPatient,
  busyFetchingAvailablePatientIds: state.patients.busyFetchingAvailablePatientIds,
  busyChangingPatientId: state.patients.busyChangingPatientId,
})

export const mapDispatchToProps = dispatch => ({
  // set
  // setStudy: study => dispatch(SetActions.setStudy(study)),
  setSite: (studyId, site) => dispatch(SetActions.setSite(studyId, site)),
  // patients
  addPatient: (studyId, countryId, siteId, patientId, studyType, newPatient) => dispatch(PatientActions.addPatient(studyId, countryId, siteId, patientId, studyType, newPatient)),
  resetAddPatientError: () => dispatch(PatientActions.resetAddPatientError()),
  fetchPatient: (studyId, patientId, includeDetails) => dispatch(PatientActions.fetchPatient(studyId, patientId, includeDetails)),
  fetchAvailablePatientIds: (studyId, countryId, siteId) => dispatch(PatientActions.fetchAvailablePatientIds(studyId, countryId, siteId)),
  // visits
  fetchVisits: (studyId, patientId) => dispatch(VisitActions.fetchVisits(studyId, patientId)),
  // pdis
  fetchAllPdis: (studyId, patientId) => dispatch(PdiActions.fetchAllPdis(studyId, patientId)),
  setStudy: (study, isStudyAgnostic) => dispatch(SetActions.setStudy(study, isStudyAgnostic, null)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer))
